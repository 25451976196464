import React from 'react'
import Layout from '../components/layout'
import { Container, Box, Heading } from "../components/ui"

import "/docs/contact.css"

const contact = () => {
  return (

    <Layout>
      <Box paddingY={5}>
        <Container width="tight">
        <Heading variant="heading">
              Contact AcoSound Hearing Aids
        </Heading>
        <form
            action="https://formspree.io/f/moqbdjzb"
            method="POST">

            <div className="form-row">
               <label htmlFor='name'>your name</label>
               <input type="text" name="name" id="name" />
             </div>
             <div className="form-row">
               <label htmlFor='email'>your email</label>
               <input type="text" name="email" id="email" />
             </div>
             <div className="form-row">
               <label htmlFor='message'>message</label>
               <textarea name="message" id="message" ></textarea>
              
             </div>
             <button type='submit' className='btn block'>
              submit
             </button>
        </form>
        </Container>
      </Box>
    </Layout>


  )
}

export default contact
